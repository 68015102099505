import createReducer from '../createReducer';
import {
    TOGGLE_LOADING, HANDLE_LOADING_STATE, SET_LANGUAGE, SET_PROFILE,
    SET_WHITE_LABELING_LOGO, SET_WHITE_LABELING_TITLE_COLOR, SET_CURRENCY_POSITION,
    SET_WHITE_LABELING_PRIMARY_COLOR, SET_WHITE_LABELING_PRIMARY_OPACITY_COLOR,
    SET_WHITE_LABELING_PRIMARY_LIGHT_COLOR, SET_WHITE_LABELING_BACKGROUND_COLOR,
    SET_CURRENT_DETAILS_OPTION, SET_APP_FLOW, SET_WHITE_LABELING_INPUT_BACKGROUND,
    SET_WHITE_LABELING_HEADER_BACKGROUND, SET_INSURANCE_LOGO_VISIBLE, SET_THEME_READY,
    SET_GOOGLE_API_READY, SET_MINIMAL_LEAD_AMOUNT, SET_MODE, SET_CUSTOMER_PSEUDO_ID,
    SET_PULL_INSURANCE, /* SET_RECEIPT_PHOTO_REQUIRED, */ SET_CONFIG_ATTRIBUTES, SET_TARGET_PHOTO,
    SET_BENEFICIARY_ATTRIBUTES, SET_SKIP_BENEFICIARY_ATTRIBUTES, SET_BREADCRUMBS,
    SET_CURRENT_STEP_NUMBER, SET_BENEFICIARY_ATTRIBUTE_VALUE, SET_ACCEPTABLE_FILES_EXTENSIONS,
    SET_TAGS, SET_BENEFICIARY_AND_POSTAL_CODE, SET_PROFILE_LOADING_STARTED,
    SET_STICKY_HEADER, SET_COUNTRY, SET_REDSYS_API_READY, SET_SKIP_INFORM_SCREEN
} from './common.actionTypes';
import { bankLogo } from '../../dist/images/Logo.svg';
import { APP_FLOWS } from '../../constants/constants';

const common = {
    currentStepNumber: 0,
    breadcrumbs: [],
    skipBeneficiaryAttributes: true,
    beneficiaryPosition: null,
    preBeneficiaryAttributes: [],
    postBeneficiaryAttributes: [],
    beneficiaryAttributes: [],
    targetPhoto: null,
    receiptPhotoPosition: null,
    itemPhotoPosition: null,
    configAttributes: [],
    // receiptPhotoRequired: true,
    mode: null,
    pseudoId: null,
    pullInsurance: {},
    appFlow: APP_FLOWS.INSURANCE,
    loading: false,
    loadingState: null,
    actualLanguage: '',
    currencyPosition: null,
    profile: null,
    whiteLabelingLogo: bankLogo,
    whiteLabelingTitleColor: '#05264D',
    whiteLabelingPrimaryColor: '#00008F',
    whiteLabelingPrimaryOpacityColor: '#9a9ad4',
    whiteLabelingPrimaryLightColor: '#9a9ad4',
    whiteLabelingBackgroundColor: '#F8F9FF',
    whiteLabelingHeaderBackground: '#FFFFFF',
    whiteLabelingInputBackground: '#EDEDF7',
    isInsuranceLogoVisible: false,
    currentDetailsOption: null,
    isThemeReady: false,
    isGoogleAPIReady: false,
    isRedsysAPIReady: false,
    minimalLeadAmount: null,
    extensions: '.jpg,.png,.jpeg',
    tags: [],
    index: '',
    birthdays: [{ date: null }],
    stickyHeader: false,
    country: null,
    skipInformScreen: false
};

function setTags(state, { tags }) {
    return {
        ...state,
        tags
    };
}

function setSkipBeneficiaryAttributes(newState, { flag }) {
    return { ...newState, skipBeneficiaryAttributes: flag };
}

function setMode(newState, { mode }) {
    return {
        ...newState,
        mode
    };
}

function setPullInsurance(newsState, { ins }) {
    return {
        ...newsState,
        pullInsurance: ins
    };
}

function setAppFlow(newsState, { data }) {
    return { ...newsState, appFlow: data };
}

function toggleLoading(newsState, { data }) {
    return { ...newsState, loading: data };
}

function handleLoadingState(newsState, { data }) {
    return { ...newsState, loadingState: data };
}

function setLanguage(newsState, { data }) {
    return { ...newsState, actualLanguage: data };
}

function setCurrencyPosition(newsState, { data }) {
    return { ...newsState, currencyPosition: data };
}

function setProfile(newsState, { data }) {
    return { ...newsState, profile: data };
}

function setWhiteLabelingLogo(newsState, { data }) {
    return { ...newsState, whiteLabelingLogo: data };
}

function setWhiteLabelingTitleColor(newsState, { data }) {
    return { ...newsState, whiteLabelingTitleColor: data };
}

function setWhiteLabelingPrimaryColor(newsState, { data }) {
    return { ...newsState, whiteLabelingPrimaryColor: data };
}

function setWhiteLabelingPrimaryOpacityColor(newsState, { data }) {
    return { ...newsState, whiteLabelingPrimaryOpacityColor: data };
}

function setWhiteLabelingPrimaryLightColor(newsState, { data }) {
    return { ...newsState, whiteLabelingPrimaryLightColor: data };
}

function setWhiteLabelingBackgroundColor(newsState, { data }) {
    return { ...newsState, whiteLabelingBackgroundColor: data };
}

function setCurrentDetailsOption(newsState, { option }) {
    return { ...newsState, currentDetailsOption: option };
}

function setHeaderBackground(newsState, { data }) {
    return { ...newsState, whiteLabelingHeaderBackground: data };
}

function setInputBackground(newsState, { data }) {
    return { ...newsState, whiteLabelingInputBackground: data };
}

function setInsuranceLogoVisible(newsState, { data }) {
    return { ...newsState, isInsuranceLogoVisible: data };
}

function setThemeReady(newsState, { data }) {
    return { ...newsState, isThemeReady: data };
}

function setGoogleAPIReady(newsState, { data }) {
    return { ...newsState, isGoogleAPIReady: data };
}

function setRedsysAPIReady(newsState, { data }) {
    return { ...newsState, isRedsysAPIReady: data };
}

function setMinimalLeadAmount(newState, { data }) {
    return { ...newState, minimalLeadAmount: data };
}

function setCustomerPseudoId(newState, { id }) {
    return { ...newState, pseudoId: id };
}

/* function setReceiptPhotoRequired(newState, { isRequired }) {
    return { ...newState, receiptPhotoRequired: isRequired };
} */

function setConfigAttributes(newState, { data }) {
    const config = !data.length ? { receiptPhotoPosition: { position: 'DISABLED', list: [] }, itemPhotoPosition: { position: 'DISABLED', list: [] } } : data[0];
    return { ...newState, ...config };
}

/* function setBeneficiaryAttributes(newState, { data }) {
    let pre = [];
    let post = [];

    const forWhom = data.find(attribute => attribute.key === 'for_whom');
    if (forWhom.position === 'DISABLE') {
        pre = [];
        post = [];
    } else {
        data.map((attribute) => {
            if (attribute.key !== 'for_whom') {
                if (forWhom.position === 'POST_OFFER') {
                    // ignore all pre-offered beneficiary attributes
                    // as we do not have validation on BE
                    if (attribute.position === 'POST_OFFER') {
                        post.push({ ...attribute, value: null });
                    }
                } else {
                    (attribute.position ===
                    'PRE_OFFER' ? pre : post).push({ ...attribute, value: null });
                }
            }
            return attribute;
        });
    }

    return { ...newState,
        postBeneficiaryAttributes: post,
        preBeneficiaryAttributes: pre,
        beneficiaryAttributes: data,
        forWhom: { ...forWhom, value: 0 }
    };
} */

function setBeneficiaryAttributes(newState, { data }) {
    return { ...newState, ...data };
}

function setBeneficiaryAttributeValue(newState, { data }) {
    const { key, value } = data;
    return {
        ...newState,
        beneficiaryAttributes: newState.beneficiaryAttributes.map((attribute) => {
            if (attribute.key === key) {
                return { ...attribute, value };
            }
            return attribute;
        })
    };
}

function setTargetPhoto(newState, { target }) {
    return { ...newState, targetPhoto: target };
}

function setBreadcrumbs(newState, { data }) {
    return { ...newState, breadcrumbs: data };
}

function setCurrentStepNumber(newState, { data }) {
    return { ...newState, currentStepNumber: data };
}

function setAcceptableFilesExtensions(state, { extensions }) {
    return {
        ...state,
        extensions
    };
}

function setBeneficiaryAndPostalCode(state, { data }) {
    return {
        ...state, ...data
    };
}

function setProfileLoading(state, { flag }) {
    return {
        ...state, profileLoadingStarted: flag
    };
}

function setStickyHeader(state, { data }) {
    return { ...state, stickyHeader: data };
}

function setCountry(state, { data }) {
    return { ...state, country: data };
}

function setSkipInformScreen(state, { skip }) {
    return { ...state, skipInformScreen: skip };
}

const commonReducer = createReducer(common, {
    [SET_APP_FLOW]: setAppFlow,
    [TOGGLE_LOADING]: toggleLoading,
    [HANDLE_LOADING_STATE]: handleLoadingState,
    [SET_LANGUAGE]: setLanguage,
    [SET_CURRENCY_POSITION]: setCurrencyPosition,
    [SET_PROFILE]: setProfile,
    [SET_WHITE_LABELING_LOGO]: setWhiteLabelingLogo,
    [SET_WHITE_LABELING_TITLE_COLOR]: setWhiteLabelingTitleColor,
    [SET_WHITE_LABELING_PRIMARY_COLOR]: setWhiteLabelingPrimaryColor,
    [SET_WHITE_LABELING_PRIMARY_OPACITY_COLOR]: setWhiteLabelingPrimaryOpacityColor,
    [SET_WHITE_LABELING_PRIMARY_LIGHT_COLOR]: setWhiteLabelingPrimaryLightColor,
    [SET_WHITE_LABELING_BACKGROUND_COLOR]: setWhiteLabelingBackgroundColor,
    [SET_CURRENT_DETAILS_OPTION]: setCurrentDetailsOption,
    [SET_WHITE_LABELING_HEADER_BACKGROUND]: setHeaderBackground,
    [SET_WHITE_LABELING_INPUT_BACKGROUND]: setInputBackground,
    [SET_INSURANCE_LOGO_VISIBLE]: setInsuranceLogoVisible,
    [SET_THEME_READY]: setThemeReady,
    [SET_GOOGLE_API_READY]: setGoogleAPIReady,
    [SET_MINIMAL_LEAD_AMOUNT]: setMinimalLeadAmount,
    [SET_MODE]: setMode,
    [SET_CUSTOMER_PSEUDO_ID]: setCustomerPseudoId,
    [SET_PULL_INSURANCE]: setPullInsurance,
    // [SET_RECEIPT_PHOTO_REQUIRED]: setReceiptPhotoRequired,
    [SET_CONFIG_ATTRIBUTES]: setConfigAttributes,
    [SET_TARGET_PHOTO]: setTargetPhoto,
    [SET_BENEFICIARY_ATTRIBUTES]: setBeneficiaryAttributes,
    [SET_SKIP_BENEFICIARY_ATTRIBUTES]: setSkipBeneficiaryAttributes,
    [SET_BREADCRUMBS]: setBreadcrumbs,
    [SET_CURRENT_STEP_NUMBER]: setCurrentStepNumber,
    [SET_BENEFICIARY_ATTRIBUTE_VALUE]: setBeneficiaryAttributeValue,
    [SET_ACCEPTABLE_FILES_EXTENSIONS]: setAcceptableFilesExtensions,
    [SET_TAGS]: setTags,
    [SET_BENEFICIARY_AND_POSTAL_CODE]: setBeneficiaryAndPostalCode,
    [SET_PROFILE_LOADING_STARTED]: setProfileLoading,
    [SET_STICKY_HEADER]: setStickyHeader,
    [SET_COUNTRY]: setCountry,
    [SET_REDSYS_API_READY]: setRedsysAPIReady,
    [SET_SKIP_INFORM_SCREEN]: setSkipInformScreen
});

export default commonReducer;
