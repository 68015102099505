import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import InputWrap from '../../../Common/Input/InputWrap';
import { messages } from '../../../../constants/messages';
import { IBAN_REGEXP } from '../../../../constants/constants';
import {
    setCustomerData,
} from '../../../../store/payment/payment.actions';
import './iban.scss';

const Iban = ({ errorCallback }) => {
    const ref = useRef(null);
    const dispatch = useDispatch();
    const [ibanError, setIbanError] = useState(null);
    const [iban, setIban] = useState(null);

    useEffect(() => {
        errorCallback({ iban_number: false });
    }, []);

    const validateIban = (v) => {
        if (!v) {
            setIbanError(messages.MANDATORY_FIELD);
            return;
        }

        if (!v.match(IBAN_REGEXP)) {
            setIbanError(messages.INVALID_FORMAT);
        }
    };

    const handleIbanChange = (value) => {
        setIbanError('');
        dispatch(setCustomerData({ key: 'iban_number', value }));
        setIban(value.replace(/[^\dA-Za-z]/g, '').replace(/(.{4})/g, '$1 ').trim());
        /* ref.current.setSelectionRange(8, 8);
        ref.current.focus(); */
    };

    useEffect(() => {
        if (typeof iban === 'string') {
            validateIban(iban.replaceAll(' ', ''));
        }
    }, [iban]);

    useEffect(() => {
        if (ibanError !== null) {
            errorCallback({ iban_number: !ibanError });
        }
    }, [ibanError]);

    return <div className="iban">
        <p className="iban-label">
            IBAN de tu cuenta bancaria
        </p>
        <InputWrap
            ref={ref}
            className="cardholder-name"
            type="text"
            value={iban}
            onChange={handleIbanChange}
            error={ibanError}
            lable="" />
    </div>;
};

export default Iban;
