import React from 'react';

const Points = ({ titles, email, isSanitas, isNext = [] }) => <div className="payment_conditions_wrap">
    <p>{titles.WHAT_IS_NEXT}</p>
    {
        isNext.length ?
            <ul>
                {
                    isNext.map((next, i) => {
                        return <li key={i}>{next}</li>;
                    })
                }
            </ul> :
            <ul>
                {!!email && (
                    <li>{!isSanitas ?
                        titles.INSURANCE_CERTIFICATE(email) :
                        titles.INSURANCE_CONFIRMATION(email)}</li>
                )}
                <li>{titles[`${!isSanitas ? 'YOU_CAN_DOWNLOAD' : 'YOU_CAN_DOWNLOAD_CONFIRMATION'}`]}</li>
            </ul>
    }

</div>;

export default Points;
