// TODO to diff for item and receipt
// TODO to separate item and receipt
import React, { Profiler, useRef, useState, useEffect } from 'react';
import ImageGallery from 'react-image-gallery';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { previousPageNavigation, nextPageNavigation, pageOnRender } from '../../../helpers/amplitude';
import Header from '../../Header';
import { messages } from '../../../constants/messages';
import Footer from '../../Common/Footer/Footer';
import RecognizeLoader from '../RecognizeLoader/RecognizeLoader';
import {
    savePhotos,
    setPhotos,
    setStep,
    setResetIndex,
    setRecognisedData
} from '../../../store/productInsurance/productInsurance.actions';
import {
    selectProfile,
    selectBreadcrumbs, selectCurrentStepNumber, /* selectTargetPhoto */ } from '../../../store/common/common.selector';

import {
    /* selectDetailsOption, */
    selectPhotos,
    resetPhotoIndex,
    selectLoading
} from '../../../store/selectors';
// import { DETAILS_OPTIONS, STEPS } from '../../../constants/constants';
import { useLanguage } from '../../../helpers/customHooks';
import Button from '../../Common/Button/Button';
import DeletePhoto from '../../../dist/images/DeletePhoto.svg';


import { /* setTargetPhoto, */ setCurrentStepNumber } from '../../../store/common/common.actions';

import 'react-image-gallery/styles/scss/image-gallery.scss';
import './ReceiptPhoto.scss';

import { setActualFooterScreen } from '../../../store/payment/payment.actions';

const ReceiptPhoto = (/* { openDropZone } */) => {
    useLanguage();
    const galleryRef = useRef();
    const dispatch = useDispatch();
    const history = useHistory();
    const [currentIndex, setCurrentIndex] = useState(0);
    const photos = useSelector(selectPhotos);
    const resetIndex = useSelector(resetPhotoIndex);
    // const currentDetailsOption = useSelector(selectDetailsOption);
    const isLoading = useSelector(selectLoading);

    const currentStepNumber = useSelector(selectCurrentStepNumber);
    const breadcrumbs = useSelector(selectBreadcrumbs);
    const targetPhoto = breadcrumbs[currentStepNumber].target;
    const { lead_uuid, tags = [] } = useSelector(selectProfile);
    const [hideAdd, setHideAdd] = useState(false);

    useEffect(() => {
        if (tags.length && tags.indexOf('risk_life_metlife_spain') > -1) {
            setHideAdd(true);
        }
    }, [tags]);

    useEffect(() => {
        if (photos[targetPhoto].length) {
            setCurrentIndex(photos[targetPhoto].length - 1);
            dispatch(setResetIndex(false));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [photos]);

    useEffect(() => {
        if (resetIndex) {
            setCurrentIndex(0);
        }
    }, [resetIndex]);

    const onBackStep = () => {
        dispatch(setResetIndex(true));
        const { step } = breadcrumbs[currentStepNumber - 1];
        previousPageNavigation({ uuid: lead_uuid, page: step });
        dispatch(setCurrentStepNumber(currentStepNumber - 1));
        dispatch(setStep(step));
    };

    const navigateNext = (recognisedData) => {
        if (recognisedData?.length) {
            dispatch(setRecognisedData(recognisedData));
        }

        const { step, payment } = breadcrumbs[currentStepNumber + 1];
        nextPageNavigation({ uuid: lead_uuid, page: step });
        if (payment) {
            dispatch(setActualFooterScreen(step));
            history.push('/payment');
            // logEvent(EVENTS.ACTIVE_STEP, { page: 'Payment part' });
        }
        /*  if (target) {
            dispatch(setTargetPhoto(target));
        } */
        dispatch(setStep(step));
        dispatch(setCurrentStepNumber(currentStepNumber + 1));
    };

    const onNextStep = () => {
        dispatch(savePhotos())
            .then((resp) => {
                dispatch(setResetIndex(true));
                navigateNext(resp.photos_recognised_data);
            });
    };

    const onAddPhoto = () => {
        /* if (currentDetailsOption === DETAILS_OPTIONS.CAMERA) {
            dispatch(setStep(STEPS.TAKE_PHOTO));
        } else {
            openDropZone();
        } */
        onBackStep();
    };

    const onSlide = (index) => {
        setCurrentIndex(index);
    };

    const images = photos[targetPhoto].map(({ attachment }) =>
    /* if (attachment.contains('jpg') || attachment.contains('jpeg')
        || attachment.contains('png')) {
            return { original: attachment };
        } */

        ({ original: attachment })
    );

    const onRemovePhoto = () => {
        let rest = [];
        let target = [];
        dispatch(setRecognisedData([]));
        Object.keys(photos).map((key) => {
            if (key === targetPhoto) {
                target = photos[targetPhoto];
                target.splice(currentIndex, 1);
            } else {
                rest = photos[key];
            }
            return key;
        });

        dispatch(setPhotos([...rest, ...target]));

        setCurrentIndex(currentIndex ? currentIndex - 1 : 0);

        if (!target.length) {
            onAddPhoto();
        }
    };

    const renderDeleteButton = () => (
        <div className="remove-photo-btn" onClick={onRemovePhoto}>
            <img src={DeletePhoto} alt="delete" />
        </div>
    );

    return (
        <Profiler id="ViewReceiptPhoto" onRender={pageOnRender}>
            <>
                <Header
                    stepper
                    stepsTitle={targetPhoto === 'receipt' ? messages.CHECK_RECEIPT(tags.indexOf('risk_life_metlife_spain') > -1) : messages.CHECK_ITEM_PHOTO(tags.indexOf('risk_life_metlife_spain') > -1)}
                    className="receipt-header"
                />
                <div className="receipt-wrap">
                    <ImageGallery
                        ref={galleryRef}
                        items={images}
                        showThumbnails={false}
                        showFullscreenButton={false}
                        showPlayButton={false}
                        showNav={false}
                        showBullets={images.length > 1}
                        renderCustomControls={renderDeleteButton}
                        startIndex={currentIndex}
                        onSlide={onSlide}
                    />
                </div>
                <Footer
                    onNavBack={onBackStep}
                    onNextStep={onNextStep}
                    className="receipt-photo-footer"
                >
                    {!hideAdd && <Button
                        type="default"
                        onClick={onAddPhoto}
                        className="add-photo-button"
                    >
                        {`+ ${messages.PHOTO}`}
                    </Button>}
                </Footer>
                {isLoading && (
                    <RecognizeLoader />
                )}
            </>
        </Profiler>
    );
};

export default ReceiptPhoto;
