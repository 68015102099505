import React, { useRef, useImperativeHandle } from 'react';
import { useDispatch } from 'react-redux';
import {
    Elements,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_KEY } from '../../../../constants/constants';
import {
    submitPayment
} from '../../../../store/payment/payment.actions';
import Price from './Price';
import CardHolder from './CardHolder';
import StripeForm from './StripeForm';
import Points from './Points';
import Iban from '../Iban/Iban';
import CheckBoxSet from '../CheckBoxSet/CheckBoxSet';
import './stripe.scss';

const stripePromise = loadStripe(STRIPE_KEY);

const Stripe = React.forwardRef(({
    cardHolder,
    uuid,
    product,
    titles,
    errorCallback,
    paymentCallback,
    isIban,
    email,
    isHelvetia,
    isDetailedDataPrivacy,
    isSanitas,
    isNext
}, ref) => {
    const child = useRef();
    const dispatch = useDispatch();

    const handleSubmit = () => {
        if (child.current) {
            child.current.handleSubmit();
            return;
        }

        dispatch(submitPayment(''))
            .then(() => {
                paymentCallback();
            });
    };

    useImperativeHandle(ref, () => ({ handleSubmit }));

    return <Elements stripe={stripePromise}>
        <form className="card-form-wrapper">
            <Price product={product} />
            <CardHolder label={titles.CARDHOLDER_NAME} holder={cardHolder} />
            {isIban && <Iban errorCallback={errorCallback} />}
            {(product.payment_processing_method !== 'EXTERNAL_WITHOUT_ACCEPTANCE' && <StripeForm
                ref={child}
                uuid={uuid}
                label={titles.CARD_INFORMATION}
                errorCallback={errorCallback}
                paymentCallback={paymentCallback} />) || null}
            <CheckBoxSet
                isSanitas={isSanitas}
                product={product}
                isHelvetia={isHelvetia}
                isDetailedDataPrivacy={isDetailedDataPrivacy}
                titles={titles}
                errorCallback={errorCallback} />
            <Points email={email} titles={titles} isSanitas={isSanitas} isNext={isNext} />
        </form>
    </Elements>;
});

export default Stripe;
