import React, { Profiler, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import Redsys from './Redsys';
import Header from '../../Header';
import Footer from '../../Common/Footer/Footer';
import Spinner from '../../Common/Spinner/Spinner';
import Insurama from './Insurama';
import Stripe from './Stripe/Stripe';

import { getPaymentStatusFromProfile, setCurrentStepNumber } from '../../../store/common/common.actions';
import { paymentInitiation, setActualFooterScreen, savePersonalDetails, checkData } from '../../../store/payment/payment.actions';
import { setAlreadyPaid, setStep } from '../../../store/productInsurance/productInsurance.actions';
import { selectCustomerData, selectAlreadyPaid, selectProfile, selectLoading,
    selectCurrentInsuranceId, selectInsuranceProducts, selectCustomerEmail,
    selectPaymentInsuramaUrl, selectCustomerAttributes } from '../../../store/selectors';
import { selectCurrentStepNumber, selectBreadcrumbs } from '../../../store/common/common.selector';

import { messages } from '../../../constants/messages';
import { pageOnRender, previousPageNavigation } from '../../../helpers/amplitude';
import { getCardHolderName } from '../../../helpers/renderingHelpers';

import './CardDetails.scss';

const CardDetails = () => {
    const [error, setError] = useState(null);
    const [validStatus, setValidStatus] = useState(null);
    const [checkDataStatus, setCheckDataStatus] = useState(null);

    const stripe = useRef(null);
    const redsys = useRef(null);
    const insurama = useRef(null);

    const history = useHistory();
    const dispatch = useDispatch();
    const ifAlreadyPaid = () => {
        dispatch(getPaymentStatusFromProfile());
    };
    const customerData = useSelector(selectCustomerData);
    const customerAttributes = useSelector(selectCustomerAttributes);
    const email = useSelector(selectCustomerEmail);
    const alreadyPaid = useSelector(selectAlreadyPaid);
    const cardHolder = getCardHolderName(customerData);
    const { lead_uuid, tags = [],
        issuer_data_privacy_sheet,
        issuer_payment_consent_sheet,
        issuer_terms_n_conditions_sheet } = useSelector(selectProfile);
    const loading = useSelector(selectLoading);
    const currentInsuranceId = useSelector(selectCurrentInsuranceId);
    const insuranceProducts = useSelector(selectInsuranceProducts);
    const insuramaUrl = useSelector(selectPaymentInsuramaUrl);

    const breadcrumbs = useSelector(selectBreadcrumbs);
    const currentStepNumber = useSelector(selectCurrentStepNumber);

    const product = insuranceProducts.find(({ uuid }) => uuid === currentInsuranceId) || {};
    product.issuer_data_privacy_sheet = issuer_data_privacy_sheet;
    product.issuer_payment_consent_sheet = issuer_payment_consent_sheet;
    product.issuer_terms_n_conditions_sheet = issuer_terms_n_conditions_sheet;
    const gateway = product.payment_gateway;


    const isIban =
    !!customerAttributes.find(({ attribute, position }) => attribute === 'iban_number' && position !== 'DISABLED');

    const navigateToSuccessPage = () => {
        dispatch(setAlreadyPaid(true));
        history.push('/success');
    };

    useEffect(() => {
        dispatch(paymentInitiation());
    }, []);

    const errorCallback = (isValid) => {
        setValidStatus(prev => ({ ...prev, ...isValid }));
    };

    const onNavBack = () => {
        const { step, payment } = breadcrumbs[currentStepNumber - 1];
        previousPageNavigation({ uuid: lead_uuid, page: step });
        if (!payment) {
            history.push('/store-purchase');
        } else {
            dispatch(setActualFooterScreen(step));
        }
        dispatch(setCurrentStepNumber(currentStepNumber - 1));
        dispatch(setStep(step));
    };

    const renderWizard = (scheme) => {
        switch (scheme) {
            case 'REDSYS': return <Redsys
                isSanitas={tags.indexOf('sanitas_student_insurance') > -1}
                ref={redsys}
                product={product}
                uuid={lead_uuid}
                titles={messages}
                isDetailedDataPrivacy={tags.indexOf('helvetia_detailed_data_privacy') > -1}
                isHelvetia={tags.indexOf('helvetia_spain_life') > -1}
                isIban={isIban}
                errorCallback={errorCallback} />;
            case 'INSURAMA': return <Insurama isSanitas={tags.indexOf('sanitas_student_insurance') > -1} titles={messages} product={product} errorCallback={errorCallback} />;
            default: return <Stripe
                isSanitas={tags.indexOf('sanitas_student_insurance') > -1}
                isNext={product.next_step_message}
                ref={stripe}
                titles={messages}
                product={product}
                uuid={lead_uuid}
                cardHolder={cardHolder}
                errorCallback={errorCallback}
                showErrorModal={(msg) => { setError(msg); }}
                paymentCallback={navigateToSuccessPage}
                isHelvetia={tags.indexOf('helvetia_spain_life') > -1}
                isDetailedDataPrivacy={tags.indexOf('helvetia_detailed_data_privacy') > -1}
                isIban={isIban}
                email={email} />;
        }
    };

    const handleSubmit = async () => {
        if (validStatus && !Object.values(validStatus).some(s => !s)) {
            if (isIban) {
                await dispatch(savePersonalDetails(customerData.iban_number));
            }
            dispatch(checkData())
                .then(() => {
                    let ref = redsys;
                    if (gateway === 'INSURAMA') {
                        ref = insurama;
                        window.open(insuramaUrl);
                        return;
                    }
                    if (gateway === 'STRIPE') {
                        ref = stripe;
                    }
                    ref.current.handleSubmit();
                })
                .catch((err) => { setCheckDataStatus(JSON.stringify(err)); });
        }
    };

    return (
        <Profiler id="CardDetails" onRender={pageOnRender}>
            { loading && (
                <Spinner />
            )}
            <Header
                stepsTitle={messages[gateway !== 'STRIPE' ? 'LAST_STEP' : 'CONFIRM_YOUR_CARD_DETAILS']}
                className="card-details-header"
                stepper
            />
            {gateway !== undefined && <div className="card-details-wrap main" style={gateway !== 'STRIPE' ? { padding: 0 } : {}}>
                {checkDataStatus && <div style={{ color: 'rgb(255, 3, 1)', marginBottom: '25px' }}>{checkDataStatus}</div>}
                {renderWizard(gateway)}
                {error && <div className="popup">
                    <div className="error-message-wrap">
                        <div className="error-message">{error}</div>
                        <div
                            className="error-message-back"
                            onClick={() => { setError(null); }}>
                            {messages.BACK}
                        </div>
                    </div>
                </div>}
                {alreadyPaid && ifAlreadyPaid()}
            </div>
            }
            <Footer
                onNavBack={() => { onNavBack(); }}
                showNext
                onNextStep={handleSubmit}
                disabled={!validStatus || (validStatus && Object.values(validStatus).some(s => !s))}
                title={gateway === 'STRIPE' ? messages.CONFIRM_AND_PAY : null}
                className="card-form-footer" />
        </Profiler>
    );
};

export default CardDetails;
