import { SET_AVAILABLE_ITEMS, SET_ERROR, SET_CURRENT_GOODS_ID, SET_CURRENT_INSURANCE_ID, SET_INSURANCE_TYPES, SET_INSURANCE_PRODUCTS, SET_CURRENT_COUNTER_VALUE, SET_PAID_STATUS, SET_SINGLE_ITEM_TYPE, SET_ALREADY_PAID, SET_CURRENT_CURRENCY, SET_IS_ITEM_TYPE_SCREEN, SET_CURRENT_CURRENCY_CODE, SET_ITEM_TYPE_ATTRIBUTES, SET_ITEM_TYPE_ATTRIBUTE_VALUE, SET_CUSTOMER_ATTRIBUTES, SET_STEP, SET_INSURANCE_CATEGORIES, SET_PHOTOS, SET_RESET_INDEX, SET_CURRENT_CATEGORY, SET_RECOGNISED_DATA, SET_CURRENT_PRODUCT, SET_UPDATE_PRODUCT, SET_UNRECOGNIZED_ITEM, SET_INSURANCES_LIST, SET_INSURANCE_PRODUCT_ID } from './productInsurance.actionTypes';
import { fetchWrapper } from '../../helpers/helpers';
import { dataURItoBlob, getFileExtension, mapItemTypeAttributes } from '../../helpers/dataHelpers';
import { selectReceiptPhotoPosition, selectItemPhotoPosition, selectCurrentStepNumber, selectActualLanguage, selectMode, selectProfile, selectPullInsurance, selectTargetPhoto, selectBreadcrumbs, selectBeneficiaryAttributes, selectBeneficiaryPosition } from '../common/common.selector';
import {
    selectCurrentGoodsId,
    currentInsuranceId,
    selectItemTypeAttributes,
    selectPhotos,
    selectRecognisedData,
    selectCurrentProduct,
    selectInsuranceCategories,
    selectInsuranceTypes,
    selectCustomerAttributes,
    selectInsuranceProductUuid
} from './productInsurance.selector';
// eslint-disable-next-line import/no-cycle
import { toggleLoading, setConfigAttributes, setBeneficiaryAttributes, setBreadcrumbs, setCurrentStepNumber, setSkipBeneficiaryAttributes, setSkipInformScreen } from '../common/common.actions';
// import { logEvent, EVENTS } from '../../helpers/amplitude';
import { STEPS } from '../../constants/constants';
import { formatConfigAttributes, formatItemAttributes, formatBeneficiaryAttributes, formatCustomerAttributes, prefillDataHelper } from '../../helpers/attributesSetter';
// eslint-disable-next-line import/no-cycle
import { setCustomerData } from '../payment/payment.actions';

export const setInsuranceProductUuid = uuid => ({ type: SET_INSURANCE_PRODUCT_ID, uuid });
export const setAvailableItems = data => ({ type: SET_AVAILABLE_ITEMS, data });
export const setPhotos = photos => ({ type: SET_PHOTOS, photos });
export const setError = error => ({ type: SET_ERROR, error });
export const setCurrentGoodsId = uuid => ({ type: SET_CURRENT_GOODS_ID, uuid });
export const setCurrentInsuranceId = uuid => ({ type: SET_CURRENT_INSURANCE_ID, uuid });
export const setItemTypeAttributeValue = data =>
    ({ type: SET_ITEM_TYPE_ATTRIBUTE_VALUE, data });
export const setCurrentCounterValue = value => ({ type: SET_CURRENT_COUNTER_VALUE, value });
export const setPaidStatus = status => ({ type: SET_PAID_STATUS, status });
export const setAlreadyPaid = status => ({ type: SET_ALREADY_PAID, status });
export const setSingleItemType = status => ({ type: SET_SINGLE_ITEM_TYPE, status });
export const setCurrentCurrency = actualCurrency => (
    { type: SET_CURRENT_CURRENCY, actualCurrency }
);
export const setCurrentCurrencyCode = actualCurrency => (
    { type: SET_CURRENT_CURRENCY_CODE, actualCurrency }
);

export const setCurrentProduct = data => ({
    type: SET_CURRENT_PRODUCT, data
});

export const setRecognisedData = data => ({
    type: SET_RECOGNISED_DATA, data
});

export const setItemTypeAttibutes = itemTypeAttributes =>
    ({ type: SET_ITEM_TYPE_ATTRIBUTES, itemTypeAttributes });
export const setIsItemTypeScreen = screen => ({ type: SET_IS_ITEM_TYPE_SCREEN, screen });

export const setCustomerAttributes = customerAttributes =>
    ({ type: SET_CUSTOMER_ATTRIBUTES, customerAttributes });

export const setStep = step =>
    // logEvent(EVENTS.NAVIGATION_TO, { 'workflow page': step });
    ({ type: SET_STEP, step });
export const setResetIndex = toggleShow => ({ type: SET_RESET_INDEX, toggleShow });

export function chooseCurrentGoodsId(uuid) {
    return dispatch =>
        dispatch(setCurrentGoodsId(uuid));
}

export const setCurrentCategory = (uuid) => {
    if (uuid !== 'OTHER') {
        chooseCurrentGoodsId(uuid);
    }
    return { type: SET_CURRENT_CATEGORY, uuid };
};

export const setInsuranceTypes = data => ({ type: SET_INSURANCE_TYPES, data });
export const setInsuranceCategories = data => ({ type: SET_INSURANCE_CATEGORIES, data });

export const excludeInsuranceCategory = () => (dispatch, getState) => {
    const categories = selectInsuranceCategories(getState());
    const types = selectInsuranceTypes(getState());
    const ids = types.map(({ id }) => id);
    const data = categories.reduce((acc, { item_types, name }) => {
        acc.push({
            item_types: item_types.filter(({ id }) => ids.indexOf(id) === -1),
            name
        });
        return acc;
    }, []);
    dispatch(setInsuranceCategories(data));
};

export const setInsuranceProducts = data => ({ type: SET_INSURANCE_PRODUCTS, data });

export const savePrice = () => (dispatch, getState) => {
    const state = getState();
    const actualLanguage = selectActualLanguage(state);
    const recognisedData = selectRecognisedData(state);
    const currentProduct = selectCurrentProduct(state);
    const selectedProduct = recognisedData[currentProduct];
    if (!selectedProduct) {
        return Promise.resolve();
    }
    dispatch(toggleLoading(true));
    return fetchWrapper({
        url: '/api/v1/customers/personality/',
        method: 'POST',
        headers: {
            'accept-language': actualLanguage
        },
        body: {
            item_attributes: [
                {
                    value: +selectedProduct.price,
                    key: 'price',
                    type: 'price'
                },
                {
                    value: selectedProduct.description,
                    key: 'description',
                    type: 'text'
                }
            ]
        }
    })
        .finally(() => dispatch(toggleLoading(false)));
};

export const setUpdateProduct = data => ({ type: SET_UPDATE_PRODUCT, data });

export const setUnrecognizedItem = data => ({ type: SET_UNRECOGNIZED_ITEM, data });

export const uploadPhoto = ({ photo, type }, target) => (dispatch, getState) => {
    const actualLanguage = selectActualLanguage(getState());

    const num = selectCurrentStepNumber(getState());
    const breadcrumbs = selectBreadcrumbs(getState());

    const targetPhoto = selectTargetPhoto(getState()) || target ||
     breadcrumbs[num].target;
    const payload = new FormData();
    const extension = getFileExtension(type);
    const file = dataURItoBlob(photo, `photo.${extension}`);
    payload.append('attachment', file);
    payload.append('attachment_type', `${targetPhoto.includes('receipt') ? 'RECEIPT' : 'ITEM'}_PICTURE`);
    return fetchWrapper({
        url: '/api/v1/customers/attachments/',
        method: 'POST',
        body: payload,
        headers: {
            'accept-language': actualLanguage
        }
    }, true)
        .then((response) => {
            const photos = selectPhotos(getState());

            dispatch(setPhotos([...photos.receipt_photo, ...photos.item_photo, {
                uuid: response.uuid,
                attachment: photo,
                attachment_type: `${targetPhoto.includes('receipt') ? 'RECEIPT' : 'ITEM'}_PICTURE`

            }]));

            const { step } = breadcrumbs[num + 1];
            dispatch(setCurrentStepNumber(num + 1));
            dispatch(setStep(step));
        });
};


export const savePhotos = () => (dispatch, getState) => {
    const actualLanguage = selectActualLanguage(getState());
    const photosObject = selectPhotos(getState());
    const photos = [...photosObject.item_photo, ...photosObject.receipt_photo];
    if (!photos.length) return Promise.resolve([]);
    dispatch(toggleLoading(true));
    return fetchWrapper({
        url: '/api/v1/customers/personality/',
        method: 'POST',
        headers: {
            'accept-language': actualLanguage
        },
        body: {
            photos: photos
                .map(({ uuid }) => uuid)
        }
    })
        .finally(() => dispatch(toggleLoading(false)));
};

export const submitMessage = body => fetchWrapper({
    url: '/api/v1/lead/support/',
    method: 'POST',
    body
})
    .then(() => Promise.resolve())
    .catch(() => Promise.resolve());


export function getInsuranceTypes(isReload) {
    return (dispatch, getState) => {
        const actualLanguage = selectActualLanguage(getState());
        const id = selectCurrentGoodsId(getState());

        dispatch(toggleLoading(true));
        const url = '/api/v1/customers/suggestions/item-types/';
        return fetchWrapper({
            url,
            headers: {
                'accept-language': actualLanguage
            }
        })
            .then((response) => {
                // eslint-disable-next-line no-unused-expressions
                // response.length === 1 && dispatch(setSingleItemType(true));
                dispatch(setInsuranceTypes(response));
                dispatch(setCurrentGoodsId(!isReload ? response[0].id : id));
                return response;
            })
            .catch(() => {
                dispatch(setInsuranceTypes([]));
            })
            .finally(() => dispatch(toggleLoading(false)));
    };
}

export function getInsuranceCategories() {
    return (dispatch, getState) => {
        const actualLanguage = selectActualLanguage(getState());
        dispatch(toggleLoading(true));

        const url = '/api/v1/customers/item-types/categories/';
        return fetchWrapper({
            url,
            headers: {
                'accept-language': actualLanguage
            }
        })
            .then((response) => {
                // eslint-disable-next-line no-unused-expressions
                // response.length === 1 && dispatch(setSingleItemType(true));
                dispatch(setInsuranceCategories(response));
                return response;
            })
            .catch(() => {
                dispatch(setInsuranceCategories([]));
            })
            .finally(() => dispatch(toggleLoading(false)));
    };
}

export function getInsuranceProducts(id) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true));
        const actualLanguage = selectActualLanguage(getState());

        const mode = selectMode(getState());
        const { uuid } = selectPullInsurance(getState());
        let fetchParameters = {
            url: `/api/v1/customers/suggestions/item-types/${id}/insurance-products/`,
            method: 'GET',
            headers: {
                'accept-language': actualLanguage
            }
        };

        if (mode === 'pull') {
            fetchParameters = {
                url: `/api/v1/customers/insurance-products/${uuid}/start_purchase/`,
                method: 'POST',
                headers: {
                    'accept-language': actualLanguage
                },
                body: {}
            };
        }
        return fetchWrapper(fetchParameters)
            .then((response) => {
                dispatch(setInsuranceProducts(response));
                if (response.length) {
                    dispatch(setCurrentCurrency(response[0].currency_symbol));
                }
                return Promise.resolve(response);
            })
            .finally(() => dispatch(toggleLoading(false)));
    };
}

// TODO in stabilization sprint need to refactor configure functions below
export const configurePostOfferFlow = () => (dispatch, getState) => {
    const { tags } = selectProfile(getState());
    const offerSteps = [];
    const breadcrumbs = selectBreadcrumbs(getState())
        .filter(step => step.position !== 2);
    const uuid = selectInsuranceProductUuid(getState());
    const receiptPhotoPosition = selectReceiptPhotoPosition(getState());
    const itemPhotoPosition = selectItemPhotoPosition(getState());
    const itemAttributes = selectItemTypeAttributes(getState());

    const benAttributes = selectBeneficiaryAttributes(getState());

    const { forUuid, position } = selectBeneficiaryPosition(getState());
    const mode = selectMode(getState());

    const customerAttributes = selectCustomerAttributes(getState());
    if ((!tags.length || tags.indexOf('risk_life_metlife_spain') === -1) && receiptPhotoPosition.position === 'POST_OFFER' && (mode === 'pull' || receiptPhotoPosition.list.indexOf(uuid) > -1)) {
        offerSteps
            .push(...[{ step: STEPS.TAKE_OR_UPLOAD_PHOTO, target: 'receipt_photo' },
                { step: STEPS.REVIEW_PHOTO, target: 'receipt_photo' }]);
    }


    if ((!tags.length || tags.indexOf('risk_life_metlife_spain') === -1) && itemPhotoPosition.position === 'POST_OFFER' && (mode === 'pull' || itemPhotoPosition.list.indexOf(uuid) > -1)) {
        offerSteps
            .push(...[{ step: STEPS.TAKE_OR_UPLOAD_PHOTO, target: 'item_photo' },
                { step: STEPS.REVIEW_PHOTO, target: 'item_photo' }]);
    }

    // this is a super code =)))))) we all realize this. dont laugh it was a requirement
    // to change this and other one day
    if ((!tags.length ||
    (tags.length &&
    tags.indexOf('helvetia_spain_life') === -1 &&
    tags.indexOf('Multi_Travel_Insurance_Flow') === -1 &&
    tags.indexOf('Travel_Insurance_Flow') === -1 &&
    tags.indexOf('ferfer_ insuree_data') === -1 &&
    tags.indexOf('sanitas_student_insurance') === -1)) && itemAttributes.length &&
    itemAttributes.find(attr => attr.position === 'POST_OFFER' && (mode === 'pull' || attr.insurance_products.indexOf(uuid) > -1))) {
        if (itemAttributes.find(attr => attr.key === 'personal_declaration_life_insurance_metlife')) {
            offerSteps.push({ step: STEPS.ITEM_TYPE_DETAILS_PERSONAL_1 });
            offerSteps.push({ step: STEPS.ITEM_TYPE_DETAILS_PERSONAL_2 });
        } else {
            offerSteps.push({ step: STEPS.ITEM_TYPE_DETAILS });
        }
    }

    if (position === 'POST_OFFER' && (mode === 'pull' || uuid === forUuid)) {
        offerSteps.push({ step: STEPS.BENEFICIARY_SCREEN });
    }

    if (customerAttributes.length &&
    customerAttributes.find(attr => attr.position === 'POST_OFFER' && (mode === 'pull' || attr.insurance_products.indexOf(uuid) > -1))) {
        offerSteps.push({ step: STEPS.USER_DETAILS });
    }


    // just a copy of code as we need a dirty quick solution
    // if one day there is money and time we need to change this
    if (tags.indexOf('risk_life_metlife_spain') > -1 && receiptPhotoPosition.position === 'POST_OFFER' && (mode === 'pull' || receiptPhotoPosition.list.indexOf(uuid) > -1)) {
        offerSteps
            .push(...[{ step: STEPS.TAKE_OR_UPLOAD_PHOTO, target: 'receipt_photo' },
                { step: STEPS.REVIEW_PHOTO, target: 'receipt_photo' }]);
    }
    if (tags.indexOf('risk_life_metlife_spain') > -1 && itemPhotoPosition.position === 'POST_OFFER' && (mode === 'pull' || itemPhotoPosition.list.indexOf(uuid) > -1)) {
        offerSteps
            .push(...[{ step: STEPS.TAKE_OR_UPLOAD_PHOTO, target: 'item_photo' },
                { step: STEPS.REVIEW_PHOTO, target: 'item_photo' }]);
    }

    dispatch(setSkipInformScreen(receiptPhotoPosition.position === itemPhotoPosition.position));

    if (itemAttributes.length &&
        itemAttributes.find(attr => attr.position === 'POST_OFFER' && (mode === 'pull' || attr.insurance_products.indexOf(uuid) > -1))) {
        if (tags.indexOf('helvetia_spain_life') > -1) {
            offerSteps.push({ step: STEPS.HELVETIA_POST_DETAILS });
        } else if (tags.indexOf('Multi_Travel_Insurance_Flow') > -1 || tags.indexOf('Travel_Insurance_Flow') > -1 || tags.indexOf('ferfer_ insuree_data') > -1) {
            if (benAttributes.length && benAttributes.filter(({ key }) => key === 'for_whom')[0].value === 'all') {
                offerSteps.push({ step: STEPS[tags.indexOf('postoffer_ insuree_data') > -1 ? 'POST_INSUREE' : 'TRAVEL_INSURANCE_POST'] });
            }
        } else if (tags.indexOf('sanitas_student_insurance') > -1) {
            offerSteps.push({ step: STEPS.ITEM_TYPE_DETAILS });
        }
    }

    offerSteps.push({ step: STEPS.CARD_DETAILS, payment: true });

    dispatch(setBreadcrumbs([...breadcrumbs, ...offerSteps.map(s => ({ ...s, position: 2 }))]));
};

const configureOfferFlow = () => ({ step: STEPS.INSURANCE_PRODUCT_SELECTION, position: 1 });
const configurePreOfferFlow = () => (dispatch, getState) => {
    const { coverage_check_enabled, item_type_specific, tags,
        cultures } = selectProfile(getState());

    const itemAttributes = selectItemTypeAttributes(getState());
    const customerAttributes = selectCustomerAttributes(getState());
    const beneficiaryPosition = selectBeneficiaryPosition(getState());
    const receiptPhotoPosition = selectReceiptPhotoPosition(getState());
    const itemPhotoPosition = selectItemPhotoPosition(getState());
    const preOfferSteps = [];

    const mode = selectMode(getState());

    if (cultures.length > 1) {
        preOfferSteps.push({ step: STEPS.LANGUAGE_SELECT });
    }
    if (mode === 'push') {
        if (item_type_specific) {
            preOfferSteps.push({ step: STEPS.PRODUCTS_LIST });
            if (coverage_check_enabled) {
                preOfferSteps.push({ step: STEPS.PRODUCTS_LIST, coverage: true });
            }
        }
    } else {
        preOfferSteps.push({ step: STEPS.INSURANCE_PRODUCTS_LIST, mode });
    }

    if (receiptPhotoPosition.position === 'PRE_OFFER') {
        preOfferSteps
            .push(...[{ step: STEPS.TAKE_OR_UPLOAD_PHOTO, target: 'receipt_photo' },
                { step: STEPS.REVIEW_PHOTO, target: 'receipt_photo' }]);
    }

    if (itemPhotoPosition.position === 'PRE_OFFER') {
        preOfferSteps
            .push(...[{ step: STEPS.TAKE_OR_UPLOAD_PHOTO, target: 'item_photo' },
                { step: STEPS.REVIEW_PHOTO, target: 'item_photo' }]);
    }

    if (tags.length && tags.indexOf('helvetia_spain_life') > -1) {
        preOfferSteps.push({ step: STEPS.HELVETIA_EXPLANATION });
    }

    if (itemAttributes.length && itemAttributes.find(attr => attr.position === 'PRE_OFFER')) {
        if (tags.length && tags.indexOf('helvetia_spain_life') > -1) {
            // return [{ step: STEPS.BENEFICIARY_AND_POSTAL_CODE_SCREEN, position: 0 }];
            preOfferSteps.push({ step: STEPS.HELVETIA_PRE_DETAILS });
        } else if (tags.length && tags.indexOf('Travel_Insurance_Flow') > -1) {
            preOfferSteps.push({ step: STEPS.TRAVEL_INSURANCE_PRE });
        } else {
            preOfferSteps.push({ step: STEPS.ITEM_TYPE_DETAILS });
        }
    }

    if (beneficiaryPosition.position === 'PRE_OFFER') {
        preOfferSteps.push({ step: STEPS.BENEFICIARY_SCREEN });
    }

    if (customerAttributes.length && customerAttributes.find(attr => attr.position === 'PRE_OFFER')) {
        preOfferSteps.push({ step: STEPS.USER_DETAILS });
    }

    return preOfferSteps.map(s => ({ ...s, position: 0 }));
};

export const configureStartFlow = (data, isAlreadyStarted) => (dispatch) => {
    const final = [...dispatch(configurePreOfferFlow(data)), configureOfferFlow()];
    dispatch(setBreadcrumbs(final));
    dispatch(setCurrentStepNumber(+isAlreadyStarted ? final.length - 1 : 0));
    return final;
};

const setAttributes = (
{ item_attributes, beneficiary_attributes, customer_attributes, configuration_attributes }) =>
    (dispatch, getState) => {
        const mode = selectMode(getState());
        dispatch(setItemTypeAttibutes(formatItemAttributes(item_attributes)));
        dispatch(setBeneficiaryAttributes(
            formatBeneficiaryAttributes(beneficiary_attributes, mode)));
        dispatch(setCustomerAttributes(formatCustomerAttributes(customer_attributes)));

        customer_attributes
            .map(({ attribute, value }) => dispatch(setCustomerData({ key: attribute, value })));
        dispatch(setConfigAttributes(formatConfigAttributes(configuration_attributes)));
    };

export const prefillServerData = ({
    beneficiary_attributes,
    customer_attributes,
    item_attributes, configuration_attributes }) => (dispatch, getState) => {
    const prefilledBeneficiaryAttributes = selectBeneficiaryAttributes(getState());
    const prefilledItemAttributes = selectItemTypeAttributes(getState());

    const prefilledCustomerAttributes = selectCustomerAttributes(getState());

    const beneficiary_data =
    dispatch(prefillDataHelper(beneficiary_attributes, prefilledBeneficiaryAttributes));
    const item_data =
    dispatch(prefillDataHelper(item_attributes, prefilledItemAttributes));
    const customer_data =
    dispatch(prefillDataHelper(customer_attributes, prefilledCustomerAttributes));

    dispatch(setAttributes({ item_attributes: item_data,
        beneficiary_attributes: beneficiary_data,
        customer_attributes: customer_data,
        configuration_attributes }));
    dispatch(setSkipBeneficiaryAttributes(
        !(prefilledBeneficiaryAttributes.length > 1 && prefilledBeneficiaryAttributes[1].value)
    ));
};

export const buildConfiguration = (data, isAlreadyStarted, isLangTrigger) =>
    (dispatch) => {
        dispatch(prefillServerData(data));
        return isLangTrigger ? null : dispatch(configureStartFlow(data, isAlreadyStarted));
    };

export const saveItemType = isLangTrigger => (dispatch, getState) => {
    dispatch(toggleLoading(true));

    const id = selectCurrentGoodsId(getState());
    const actualLanguage = selectActualLanguage(getState());
    const mode = selectMode(getState());
    return fetchWrapper({
        url: `/api/v1/customers/item-types/${id}/set_and_configure/`,
        method: 'POST',
        headers: {
            'accept-language': actualLanguage
        },
        body: {}
    })
        .then((response) => {
            if (mode === 'pull') {
                return null;
            }
            return dispatch(buildConfiguration(response, false, isLangTrigger));
        })
        .finally(() => { if (!isLangTrigger) { dispatch(toggleLoading(false)); } });
};


export function saveInsuranceProduct() {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true));
        const id = selectCurrentGoodsId(getState());
        const uuid = currentInsuranceId(getState());
        return fetchWrapper({
            url: `/api/v1/customers/suggestions/item-types/${id}/insurance-products/`,
            method: 'POST',
            body: { uuid }
        })
            .finally(() => dispatch(toggleLoading(false)));
    };
}

export const saveItemTypeAttrubutes = () => (dispatch, getState) => {
    dispatch(toggleLoading(true));
    const attributes = selectItemTypeAttributes(getState());
    const actualLanguage = selectActualLanguage(getState());
    const mode = selectMode(getState());
    const item_type = selectCurrentGoodsId(getState());

    /* if (pos === 'PRE') {
        attributes = attributes.filter(({ position, key }) =>
         (position === 'PRE_OFFER' || key.includes('insured_dob')));
    } */
    let body = {
        item_attributes: attributes.map(({ key, type, value }) => {
            let val = value;
            if (value && ((key.includes('dob') && typeof value === 'string') || type === 'date')) {
                val = value.split('.').reverse().join('-');
            }

            return { key, type, value: value === '' || value === undefined ? null : val };
        })
    };


    if (mode === 'pull') {
        body = { ...body, item_type };
    }
    return fetchWrapper({
        url: '/api/v1/customers/personality/',
        method: 'POST',
        body,
        headers: {
            'accept-language': actualLanguage
        }
    })
        .then(response =>
        // MAYBE NEED TO UNCOMMENT FOR SOME CASES
            // const id = selectCurrentGoodsId(getState());
            // dispatch(getInsuranceProducts(id));
            response
        )
        .finally(() => dispatch(toggleLoading(false)));
};

export const getCoverageInsuranceCompanies = () => fetchWrapper({
    url: '/api/v1/customers/suggestions/underwriters/?item_type=2',
    method: 'GET'
});

export const getCoverageInsuranceProducts = pk => fetchWrapper({
    url: `/api/v1/customers/suggestions/insurance-products/?underwriter=${pk}&item_type=2`,
    method: 'GET'
});


export const getCoverage = (pk, lang) => fetchWrapper({
    url: `/api/v1/customers/coverage-check/insurance-product/${pk}/`,
    method: 'GET',
    headers: {
        'accept-language': lang
    }
});


export const getItemTypeAttributes = () => (dispatch, getState) => {
    const actualLanguage = selectActualLanguage(getState());
    const mode = selectMode(getState());
    const { item_attributes } = selectPullInsurance(getState());

    if (mode === 'pull') {
        dispatch(setItemTypeAttibutes(item_attributes));
        return null;
    }
    return fetchWrapper({
        url: '/api/v1/customers/profile/',
        method: 'GET',
        headers: {
            'accept-language': actualLanguage
        }
    })
        .then((profile) => {
            const oldAttributes = selectItemTypeAttributes(getState());
            const attributes = mapItemTypeAttributes(profile)
                .map((attribute) => {
                    const oldAttribute = oldAttributes.find(({ key }) => key === attribute.key);
                    if (oldAttribute) {
                        return {
                            ...attribute,
                            value: oldAttribute.value || ''
                        };
                    }
                    return attribute;
                });
            dispatch(setItemTypeAttibutes(attributes));
        });
};

export const setInsurances = data => ({
    type: SET_INSURANCES_LIST, data
});

export const getInsurances = (mode) => {
    const query = `${(!!mode && `?mode=${mode}/`) || ''}`;
    return dispatch => fetchWrapper({
        url: `/api/v1/customers/insurance-products/${query}`,
        method: 'GET'
    })
        .then((data) => {
            dispatch(setInsurances(data));
        });
};
