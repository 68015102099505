import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import history from '../../../helpers/history';

import InputWrap from '../../Common/Input/InputWrap';
import CheckBox from '../../Payment/CardDetails/CheckBoxSet/CheckBox';
import { itemValidators } from '../../../helpers/validationHelpers';

import CheckBoxSet from '../HelvetiaAttributes/CheckBoxSet';

import { selectTransactionPrice, selectCurrentStepNumber, selectBreadcrumbs, selectPullInsurance, selectTags, selectProfile } from '../../../store/common/common.selector';
import { selectCurrencyCode, selectMode, selectRecognisedData, selectItemTypeAttributes, selectInsuranceProductUuid } from '../../../store/selectors';
import { useLanguage, useSelectedItemType } from '../../../helpers/customHooks';
import { saveItemTypeAttrubutes, setItemTypeAttributeValue, setStep } from '../../../store/productInsurance/productInsurance.actions';
import Header from '../../Header';
import { messages } from '../../../constants/messages';
import { models } from '../../../constants/models';
import Footer from '../../Common/Footer/Footer';
import InputDatepicker from '../../Common/InputDatepicker/InputDatepicker';
import Select from '../../Common/Select/Select';
import Hint from './Hint';
import HintModal from '../HintModal/HintModal';
import { formatPrice } from '../../../helpers/dataHelpers';
import { /* setTargetPhoto, */ setCurrentStepNumber/* , setConfigAttributes */ } from '../../../store/common/common.actions';
import { setActualFooterScreen } from '../../../store/payment/payment.actions';
import { previousPageNavigation, nextPageNavigation, changeAttribute } from '../../../helpers/amplitude';
// import CustomDatePicker from '../../AirlineInsurance/CustomDatePicker';
import calendar from '../../../dist/images/Calendar.svg';

import './ItemAttributes.scss';

const ItemAttributes = ({ part }) => {
    useLanguage();

    const flow = useSelector(selectMode);
    const selectedType = useSelectedItemType(flow === 'pull');
    const itemName = selectedType.name || '';
    const dispatch = useDispatch();
    const [error, setError] = useState({});
    const attributes = useSelector(selectItemTypeAttributes);
    const pullInsurance = useSelector(selectPullInsurance);
    const refs = useRef([]);
    const iProductId = useSelector(selectInsuranceProductUuid);
    const tags = useSelector(selectTags);
    const { lead_uuid, issuer_data_privacy_sheet } = useSelector(selectProfile);
    const [dateValidStatus, setDateValidStatus] = useState(undefined);
    const [privacy, setPrivacy] = useState({ issuer_data_privacy_sheet: false });

    const data = useSelector(selectRecognisedData);
    if (flow === 'pull') {
        attributes.map((a) => {
            if (a.type === 'price' && typeof a.value === 'undefined') {
                a.value = data[0].price;
            }
            return a;
        });
    }
    const transactionPrice = useSelector(selectTransactionPrice);

    const currencyCode = useSelector(selectCurrencyCode);

    const [hintModalState, setHintModalState] = useState({});

    const currentStepNumber = useSelector(selectCurrentStepNumber);
    const breadcrumbs = useSelector(selectBreadcrumbs);

    let filteredAttributes = attributes.filter((attr) => {
        const cond = (!attr.activation_tag || (attr.activation_tag && attr.activation_tag.includes('list')) ||
        attr.type === 'date' ||
                      (attr.type !== 'date' && attr.activation_tag &&
                      (attributes.filter(({ key }) => key === attr.activation_tag)[0] || {}).value === 'true'));

        if (!breadcrumbs[currentStepNumber].position) {
            return attr.position === 'PRE_OFFER' && cond;
        }
        return attr.position === 'POST_OFFER' && attr.insurance_products.indexOf(iProductId) > -1 &&
         cond;
    });

    const spl = part && filteredAttributes.findIndex(({ key }) => key === 'personal_declaration_life_insurance_metlife');

    if (part === 1) {
        filteredAttributes = filteredAttributes.slice(0, spl);
    } else if (part === 2) {
        filteredAttributes = filteredAttributes.slice(spl + 1, filteredAttributes.length);
    }

    const onShowHint = ({ label, hint }) => {
        setHintModalState({
            shown: true,
            label,
            hint
        });
    };

    const onChangeAttributeValue = (value, key, depend) => {
        const { position } = breadcrumbs[currentStepNumber];
        changeAttribute({ uuid: lead_uuid, attribute: key, position });
        dispatch(setItemTypeAttributeValue({ key, value }));
        setError({ ...error, [key]: '' });
        if (depend) {
            const attr = attributes.filter(({ activation_tag }) => key === activation_tag)[0] || {};
            dispatch(setItemTypeAttributeValue({ key: attr.key, value: '' }));
            setError({ ...error, [attr.key]: '' });
        }
        if ((key === 'personal_declaration_life_insurance_metlife' || key === 'personal_declaration_AML' || key === 'personal_declaration_max_life_capital') && value === 'true') {
            onShowHint({ hint: messages.CANNOT_PROCEED });
        }
    };

    const handleInputBlur = (event, { key, type }) => {
        const value = event.target.value;
        const validator = itemValidators[type];
        if (validator) {
            if (type !== 'price' || flow !== 'pull') {
                setError({ ...error, [key]: validator(value, transactionPrice) });
            }
        }
        if ((!validator(value, transactionPrice)/*  || mode === 'pull' */) && key === 'price') onChangeAttributeValue(formatPrice(value), key);
    };

    const getLabel = ({ label, type }) => {
        if (type === 'price') {
            return `${label} (${flow === 'pull' ? pullInsurance.currency_symbol : currencyCode})`;
        }
        return label;
    };

    const onBackStep = () => {
        const { step, mode } = breadcrumbs[currentStepNumber - 1];
        if (mode) {
            history.push('/insurances/');
        }
        previousPageNavigation({ uuid: lead_uuid, page: step });
        dispatch(setCurrentStepNumber(currentStepNumber - 1));
        dispatch(setStep(step));
    };

    const validateItemDetails = () => filteredAttributes.reduce((acc, attribute) => {
        const validator = itemValidators[attribute.type];
        if (validator) {
            let isError = validator(attribute.value, transactionPrice);
            if (attribute.type === 'price' && flow === 'pull') {
                isError = '';
            }
            if (isError) {
                acc[attribute.key] = isError;
            }
        }
        if ((attribute.key === 'personal_declaration_life_insurance_metlife' || attribute.key === 'personal_declaration_AML' || attribute.key === 'personal_declaration_max_life_capital') && attribute.value === 'true') {
            acc[attribute.key] = true;
        }
        return acc;
    }, {});

    const onNextStep = () => {
        const isValid = !Object.keys(validateItemDetails()).length;
        if (isValid && !Object.values(error).some(e => !!e)) {
            dispatch(saveItemTypeAttrubutes())
                .then(() => {
                    const { step, payment } = breadcrumbs[currentStepNumber + 1];
                    nextPageNavigation({ uuid: lead_uuid, page: step });
                    if (payment) {
                        dispatch(setActualFooterScreen(step));
                        history.push('/payment');
                        // logEvent(EVENTS.ACTIVE_STEP, { page: 'Payment part' });
                    }
                    dispatch(setCurrentStepNumber(currentStepNumber + 1));
                    dispatch(setStep(step));
                });
        }
    };

    const onCloseHint = () => setHintModalState({ shown: false });

    const movetoNextInput = (event) => {
        const { keyCode, target } = event;
        if (keyCode === 13) {
            event.preventDefault();
            const index = refs.current.indexOf(target);
            if (index > -1 && index + 1 < refs.current.length) {
                const el = refs.current[index + 1];
                el.focus();
            }
        }
    };

    useEffect(() => {
        document.addEventListener('keyup', movetoNextInput);
        return () => document.removeEventListener('keyup', movetoNextInput);
    }, []);

    /* if (!attributes || !attributes.length) {
        return null;
    } */

    /* const getDataError = (s, key) => {
        setError(err => ({
            ...err,
            [key]: s
        }));
    }; */

    return (filteredAttributes && filteredAttributes.length && <>
        <Header
            stepper
            stepsTitle={messages.ENTER_MORE_DETAILS(tags.indexOf('sanitas_student_insurance') > -1 || tags.indexOf('risk_life_metlife_spain') > -1)}
            className="discovered-element-header"
        />
        <div className="discovered-element-wrap main">
            {tags.indexOf('hidden_item_type_name') > -1 ? null : <p className="discovered-element-title">{itemName}</p>}
            <div className="discovered-element-input">
                {filteredAttributes.map((attribute, i) => (
                    <div className="discovered-input-wrap" key={attribute.key}>
                        <div className={`discovered-input-title ${attribute.key === 'personal_declaration_life_insurance_metlife' && 'wrap'}`}>
                            {attribute.key !== 'personal_declaration_life_insurance_metlife' ? <div className="discovered-input-lable">{getLabel(attribute)}</div> :
                                messages.personal_declaration_life_insurance_metlife.map((k, j) => {
                                    return <div key={j} className="discovered-input-lable">{k}</div>;
                                })
                            }

                            {attribute.hint && <Hint
                                onClick={() => onShowHint(attribute)}
                            />}
                        </div>
                        {
                            tags.indexOf('insurama_mobile') > -1 && attribute.key === 'model' ?
                                <Select isSearchable value={{ value: attribute.value, label: attribute.value }} label="" options={models.map(({ name }) => ({ value: name, label: name }))} onChange={({ value }) => onChangeAttributeValue(value, attribute.key)} />
                                :
                                ((attribute.type === 'boolean' && <CheckBoxSet
                                    labels={[messages.YES, messages.NO]}
                                    onChange={(v) => {
                                        onChangeAttributeValue(v,
                                            attribute.key, true);
                                    }}
                                    data={attribute.value} />)
                                    || (attribute.activation_tag.includes('list') && <Select value={{ value: attribute.value, label: attribute.value }} label="" options={messages[attribute.activation_tag].map(name => ({ value: name, label: name }))} onChange={({ value }) => onChangeAttributeValue(value, attribute.key)} />)
                                    || (attribute.type === 'date' && <InputDatepicker
                                        tag
                                        dateValue={attribute.value}
                                        icon={calendar}
                                        validStatus={(s) => {
                                            setDateValidStatus(prev =>
                                                ({ ...prev, [attribute.key]: s }));
                                        }}
                                        future={/* (tags.indexOf('sanitas_student_insurance') > -1 && attribute.position === 'POST_OFFER') ||  */attribute.activation_tag === 'date_future_only'}
                                        key={i}
                                        onInputChange={(value) => {
                                            onChangeAttributeValue(value, attribute.key);
                                        }} />) ||
                                        <InputWrap
                                            className="discovered-input"
                                            type={(attribute.key === 'price' || attribute.type === 'integer') ? 'number' : 'text'}
                                            value={attribute.value}
                                            onChange={value =>
                                                onChangeAttributeValue(value, attribute.key)}
                                            onBlur={event => handleInputBlur(event, attribute)}
                                            error={error[attribute.key]}
                                            ref={(element) => {
                                                refs.current[i] = element;
                                            }}
                                        />)
                        }
                    </div>
                ))}
                {!breadcrumbs[currentStepNumber].position && tags.indexOf('sanitas_student_insurance') > -1 ? <CheckBox
                    errorCallback={r => setPrivacy(r)}
                    doc={{ key: 'issuer_data_privacy_sheet',
                        doc: issuer_data_privacy_sheet,
                        text: messages.ISSUER_DATA_PRIVACY }} /> : null}
            </div>
        </div>
        <HintModal {...hintModalState} onClose={onCloseHint} />
        <Footer
            showBack={!!currentStepNumber}
            onNavBack={onBackStep}
            onNextStep={onNextStep}
            disabled={(tags.indexOf('sanitas_student_insurance') > -1 && !breadcrumbs[currentStepNumber].position && !privacy.issuer_data_privacy_sheet) || !!Object.keys(validateItemDetails()).length || (typeof dateValidStatus === 'object' && Object.values(dateValidStatus).some(st => !st)) ||
             Object.values(error).some(e => e)}
        />
    </>);
};

export default ItemAttributes;
