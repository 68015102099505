const TOGGLE_LOADING = 'TOGGLE_LOADING';
const HANDLE_LOADING_STATE = 'HANDLE_LOADING_STATE';
const SET_LANGUAGE = 'SET_LANGUAGE';
const SET_PROFILE = 'SET_PROFILE';
const SET_WHITE_LABELING_LOGO = 'SET_WHITE_LABELING_LOGO';
const SET_WHITE_LABELING_TITLE_COLOR = 'SET_WHITE_LABELING_TITLE_COLOR';
const SET_WHITE_LABELING_PRIMARY_COLOR = 'SET_WHITE_LABELING_PRIMARY_COLOR';
const SET_WHITE_LABELING_PRIMARY_OPACITY_COLOR = 'SET_WHITE_LABELING_PRIMARY_OPACITY_COLOR';
const SET_WHITE_LABELING_PRIMARY_LIGHT_COLOR = 'SET_WHITE_LABELING_PRIMARY_LIGHT_COLOR';
const SET_WHITE_LABELING_BACKGROUND_COLOR = 'SET_WHITE_LABELING_BACKGROUND_COLOR';
const SET_CURRENCY_POSITION = 'SET_CURRENCY_POSITION';
const SET_CURRENT_DETAILS_OPTION = 'SET_CURRENT_DETAILS_OPTION';
const SET_WHITE_LABELING_HEADER_BACKGROUND = 'SET_WHITE_LABELING_HEADER_BACKGROUND';
const SET_WHITE_LABELING_INPUT_BACKGROUND = 'SET_WHITE_LABELING_INPUT_BACKGROUND';
const SET_INSURANCE_LOGO_VISIBLE = 'SET_INSURANCE_LOGO_VISIBLE';
const SET_THEME_READY = 'SET_THEME_READY';
const SET_GOOGLE_API_READY = 'SET_GOOGLE_API_READY';
const SET_MINIMAL_LEAD_AMOUNT = 'SET_MINIMAL_LEAD_AMOUNT';
export const SET_SKIP_INFORM_SCREEN = 'SET_SKIP_INFORM_SCREEN';

export const SET_APP_FLOW = 'SET_APP_FLOW';
export const SET_CUSTOMER_PSEUDO_ID = 'SET_CUSTOMER_PSEUDO_ID';
export const SET_PULL_INSURANCE = 'SET_PULL_INSURANCE';
export const SET_RECEIPT_PHOTO_REQUIRED = 'SET_RECEIPT_PHOTO_REQUIRED';
export const SET_CONFIG_ATTRIBUTES = 'SET_CONFIG_ATTRIBUTES';
export const SET_TARGET_PHOTO = 'SET_TARGET_PHOTO';
export const SET_BENEFICIARY_ATTRIBUTES = 'SET_BENEFICIARY_ATTRIBUTES';
export const SET_SKIP_BENEFICIARY_ATTRIBUTES = 'SET_SKIP_BENEFICIARY_ATTRIBUTES';
export const SET_BREADCRUMBS = 'SET_BREADCRUMBS';
export const SET_CURRENT_STEP_NUMBER = 'SET_CURRENT_STEP_NUMBER';
export const SET_BENEFICIARY_ATTRIBUTE_VALUE = 'SET_BENEFICIARY_ATTRIBUTE_VALUE';
export const SET_TAGS = 'SET_TAGS';
export const SET_BENEFICIARY_AND_POSTAL_CODE = 'SET_BENEFICIARY_AND_POSTAL_CODE';
export const SET_COUNTRY = 'SET_COUNTRY';

const SET_MODE = 'SET_MODE';
const SET_ACCEPTABLE_FILES_EXTENSIONS = 'SET_ACCEPTABLE_FILES_EXTENSIONS';
export const SET_PROFILE_LOADING_STARTED = 'SET_PROFILE_LOADING_STARTED';
export const SET_STICKY_HEADER = 'SET_STICKY_HEADER';
export const SET_REDSYS_API_READY = 'SET_REDSYS_API_READY';

export {
    TOGGLE_LOADING, HANDLE_LOADING_STATE, SET_LANGUAGE, SET_PROFILE,
    SET_WHITE_LABELING_LOGO, SET_WHITE_LABELING_TITLE_COLOR,
    SET_WHITE_LABELING_PRIMARY_COLOR, SET_WHITE_LABELING_PRIMARY_OPACITY_COLOR,
    SET_WHITE_LABELING_PRIMARY_LIGHT_COLOR, SET_CURRENT_DETAILS_OPTION,
    SET_WHITE_LABELING_BACKGROUND_COLOR, SET_CURRENCY_POSITION,
    SET_WHITE_LABELING_HEADER_BACKGROUND, SET_WHITE_LABELING_INPUT_BACKGROUND,
    SET_INSURANCE_LOGO_VISIBLE, SET_THEME_READY, SET_GOOGLE_API_READY, SET_MINIMAL_LEAD_AMOUNT,
    SET_MODE,
    SET_ACCEPTABLE_FILES_EXTENSIONS
};
